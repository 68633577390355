import { TOGGLE_MODAL_BOOKING } from './../actions/types';

const initialState = {
    isModalBookingOpened: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_MODAL_BOOKING:
            return {
                ...state, 
                isModalBookingOpened: action.isModalBookingOpened,
            };
        default:
            return state;
    }
};