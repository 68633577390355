// Import all js dependencies.
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/scrollspy'

import { navigate } from "gatsby-plugin-intl"

import s4n from './static/s4n.js'
// import './static/s4n.min.js'

import wrapWithProvider from "./wrap-with-provider"
// import { pl } from 'date-fns/locale'
export const wrapRootElement = wrapWithProvider

const $ = require("jquery")

const g_soft4NetFrontConfig = s4n($, window, document);

export const onInitialClientRender = () => {
    // $(document).ready(function () {
    //     console.log("The answer is don't think about it!")
    // });

    $(document).ready(() => {
    // $(document).on('ready', function() { // NOT WORKING WHY???!!!
        g_soft4NetFrontConfig
            .rot13()
            .transformToBootstrapAccoridion()
        ;
    });

    const intl = {
        locale: `pl`,
    }

    // Make bootstrap a.dropdown-toggle that contains submenus clickable
    const $body = $(`body`);
    // $('.navbar').on('click', 'a.dropdown-toggle', function(event) {
    $(`.navbar .navbar-nav.menu-type-main`).on(`click`, `a.dropdown-toggle`, function (event) { // (event) => { // This cannot be here since it has different this!!!
      // do not visit link for mobile
      event.preventDefault();

      // visit link in non mobile
      // if ($body.hasClass('screen-lg') || $body.hasClass('screen-xl')) {
      if ($body.is(`.screen-lg, .screen-xl`)) {
        const $a = $(this);

        // Do not touch language switcher!!!
        if (`langDropdown` === $a.attr(`id`)) {
          return false;
        }

        // if ($a.parent().hasClass('show')) {
            if ($a.length && $a.attr(`href`)) {
                // location.href = $a.attr('href');
                
                let href = $a.attr(`href`);
                    href = href.replace(`/` + intl.locale, ``);

                navigate(href);
                return null;
            }
        // }
      }
    });



    // @see: https://bootstrapious.com/p/bootstrap-multilevel-dropdown
    // ------------------------------------------------------- //
    // Multi Level dropdowns
    // ------------------------------------------------------ //
    $(`ul.dropdown-menu [data-toggle='dropdown']`).on(`click`, function(event) {
// s4n START
        // allow language switcher to be clicked!!!
        if ('langDropdown' === $(this).attr('id')) {
          return true;
        }
// s4n STOP
        event.preventDefault();
        event.stopPropagation();

        $(this).siblings().toggleClass("show");

        if (!$(this).next().hasClass('show')) {
          $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }
        $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(event) {
          $('.dropdown-submenu .show').removeClass("show");
        });
    });




    $(`.navbar .navbar-nav.menu-type-main`).on(`click`, `a.nav-link`, function (event) { // (event) => { // This cannot be here since it has different this!!!
      // const $li = $(this).parent();
      // $li.siblings().removeClass('active');
      // $li.addClass('active');
      
      // visit link in non mobile
      // if ($body.hasClass('screen-lg') || $body.hasClass('screen-xl')) {
      if (!$body.is(`.screen-lg, .screen-xl`)) {  
        $('button.navbar-toggler').click();
      }
    });

}

export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
    g_soft4NetFrontConfig
        .rot13()
        .transformToBootstrapAccoridion()
    ;
}