import { combineReducers } from 'redux';

import fontSize from './fontSize';
import highContrast from './highContrast';
import modal from './modal';
import popup from './popup';
import combineShopReducers from './../../../../src/components/s4n/Shop/ext/reducers'

    // import { connectRouter } from 'connected-react-router' // not for gatsby-ssr.js
    // const history = createBrowserHistory(); // not for gatsby-ssr.js

export default combineReducers({

    // router: typeof history === 'undefined' ? null : connectRouter(history), // not for gatsby-ssr.js

    fontSize,
    highContrast,
    modal,
    popup,
    // shopReducers(history),
    shopReducers: combineShopReducers(), // Important call combineReducers!!!
});